body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}
address,
caption,
cite,
code,
th,
var {
  font-style: normal;
  font-weight: normal;
}
fieldset,
img {
  border: none;
}
ol,
ul {
  list-style: none;
}
caption,
th {
  text-align: left;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
textarea {
  -webkit-appearance: none;
}
button {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  font-size: 15px;
  font-weight: normal;
  color: inherit;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  background: transparent;
  user-select: none;
  text-decoration: none;
  text-align: left;
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4 {
  font-weight: normal;
}
* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
@font-face {
  font-family: 'Nefelibata-PenSans';
  src: url(fonts/Nefelibata-PenSans.woff2) format('woff2'), url(fonts/Nefelibata-PenSans.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Nefelibata-SansCd';
  src: url(fonts/Nefelibata-SansCd.woff2) format('woff2'), url(fonts/Nefelibata-SansCd.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CenturyGothic';
  src: url(fonts/CenturyGothic.woff2) format('woff2'), url(fonts/CenturyGothic.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'rub';
  src: url(fonts/rub.eot);
  src: url(fonts/rub.eot#iefix) format('embedded-opentype'), url(fonts/rub.ttf) format('truetype'), url(fonts/rub.woff) format('woff'), url(img/rub.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
html,
body {
  min-height: 100%;
}
body {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  font-size: 15px;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}
body::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background: url(img/bg-pattern--blue.svg);
  z-index: 1;
}
body.shown-overlay {
  overflow: hidden;
}
a {
  color: #d24676;
  text-decoration: none;
}
.main-content {
  flex: 1;
  position: relative;
  z-index: 2;
}
.content-page ol {
  counter-reset: lifecycle;
  max-width: 512px;
  margin: 40px auto;
  text-align: center;
}
h1 + .content-page ol,
h2 + .content-page ol,
h3 + .content-page ol {
  margin-top: 0;
}
.content-page ol li::before {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  color: #57aab9;
  font-size: 28px;
  line-height: normal;
  counter-increment: lifecycle;
  content: counter(lifecycle) ".";
  display: block;
}
.content-page ol li + .content-page ol li {
  margin-top: 8px;
}
.content-page ul {
  max-width: 512px;
  margin: 0 auto;
  text-align: center;
}
.content-page ul li:before {
  width: 24px;
  height: 24px;
  content: "";
  display: block;
  margin: 0 auto;
  color: #d24676;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNNiAyLjA1OUM3LjI2My0uNDI2IDkuNzktLjQyNiAxMS4wNTMuODE2YzEuMjYzIDEuMjQzIDEuMjYzIDMuNzI4IDAgNi4yMTNDMTAuMTY4IDguODkzIDcuODk1IDEwLjc1NyA2IDEyIDQuMTA1IDEwLjc1NyAxLjgzMiA4Ljg5My45NDcgNy4wMy0uMzE2IDQuNTQzLS4zMTYgMi4wNTguOTQ3LjgxNSAyLjIxLS40MjYgNC43MzctLjQyNiA2IDIuMDZ6IgogICAgICAgICAgZmlsbD0iI2QyNDY3NiIgZmlsbC1ydWxlPSJldmVub2RkIi8+Cjwvc3ZnPgo=") no-repeat 50% 50%;
}
.content-page ul li:first-child {
  padding-top: 8px;
}
.content-page ul li + .content-page ul li {
  margin-top: 16px;
}
.wrap {
  margin: 0 auto;
  padding: 0 24px;
  width: 100%;
  max-width: 1150px;
}
.wrap--medium {
  max-width: 976px;
}
.wrap--short {
  max-width: 944px;
}
@media only screen and (max-width: 768px) {
  .wrap {
    padding: 0 16px;
  }
}
h1,
h2,
h3,
.title {
  font-family: 'Nefelibata-SansCd', 'Roboto', sans-serif;
  color: #000;
}
h1,
h2,
.title {
  font-size: 44px;
  line-height: 1;
}
h3,
.small-h2 {
  font-size: 28px;
  margin-top: 40px;
  margin-bottom: 12px;
}
.title {
  text-transform: uppercase;
  margin-bottom: 16px;
}
.subtitle {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  font-size: 28px;
  font-weight: normal;
  line-height: normal;
  color: #343434;
  margin-bottom: 48px;
}
.highlighted-text {
  font-size: 28px;
  color: #57aab9;
  margin: 24px 0;
}
.annotation {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  font-size: 24px;
  color: #d24676;
  text-align: center;
  margin: 24px 0;
}
.smooth-triangle-svg {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.blue-section {
  position: relative;
  background-color: #ace6f4;
  padding: 48px 0 87px;
}
@media only screen and (max-width: 768px) {
  .blue-section {
    padding-bottom: 80px;
  }
}
.blue-section::before {
  content: '';
  background: repeat-x 50% 0;
  position: absolute;
  left: 0;
  right: 0;
  height: 32px;
  background-image: url(img/bg_wave--blue.svg);
  bottom: 100%;
}
.blue-section::after {
  content: '';
  background: repeat-x 50% 0;
  position: absolute;
  left: 0;
  right: 0;
  height: 32px;
  background-image: url(img/bg_wave--white.svg);
  bottom: 0;
}
@media only screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
.mobile {
  display: none;
}
@media only screen and (max-width: 768px) {
  .mobile {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .mobile-block {
    display: block;
  }
}
[class^="icon-"],
[class*=" icon-"] {
  transform: translateY(-0.08em);
  -moz-osx-font-smoothing: grayscale;
  font-family: 'rub', sans-serif !important;
  font-size: 0.67em !important;
  display: inline-block;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.icon-rub:before {
  content: "\e900";
}
.topline {
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.1));
  background: #fff;
  flex: none;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.topline__logo {
  top: 0;
  background: no-repeat url(img/bg_forelock.svg);
}
.topline__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 56px;
}
@media only screen and (max-width: 1200px) {
  .topline__content {
    justify-content: center;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 768px) {
  .topline__content {
    justify-content: flex-start;
  }
}
.topline__contacts-btn {
  transition: color 0.1s linear 0s;
  will-change: color;
  color: #97bdc4;
  position: relative;
  margin-left: 40px;
}
.topline__contacts-btn:hover {
  color: #d24676;
}
@media only screen and (max-width: 1200px) {
  .topline__contacts-btn {
    position: absolute;
    top: 100%;
    right: 18px;
    margin: 16px 0 0;
  }
}
@media only screen and (max-width: 768px) {
  .topline__contacts-btn {
    transform: translateY(-50%);
    top: 50%;
    right: -10px;
    margin: 0;
  }
}
.topline__contacts-btn::after {
  transform: translate(-50%, -50%);
  content: url(img/ico_phone.svg);
  position: absolute;
  top: 50%;
  left: 50%;
}
.topline__contacts-bubble {
  width: 48px;
  height: 48px;
}
@media only screen and (max-width: 1200px) {
  .topline__contacts-bubble {
    color: #57aab9;
    width: 62px;
    height: 62px;
  }
}
@media only screen and (max-width: 768px) {
  .topline__contacts-bubble {
    color: inherit;
    width: 48px;
    height: 48px;
  }
}
.topline__mobile-menu-btn {
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -10px;
  top: 50%;
  display: none;
}
@media only screen and (max-width: 768px) {
  .topline__mobile-menu-btn {
    display: flex;
  }
}
.topline__ico-mobile-menu {
  background: #57aab9;
  display: block;
  position: relative;
  width: 24px;
  height: 2px;
}
.topline__ico-mobile-menu:before,
.topline__ico-mobile-menu:after {
  content: '';
  background: #57aab9;
  position: absolute;
  width: 100%;
  height: 100%;
}
.topline__ico-mobile-menu::before {
  top: 7px;
}
.topline__ico-mobile-menu::after {
  top: -7px;
}
.main-menu {
  display: flex;
  font-size: 24px;
}
@media only screen and (max-width: 768px) {
  .main-menu {
    display: none;
  }
}
.main-menu__col {
  display: flex;
}
.main-menu__col + .main-menu__col {
  margin-left: 177px;
}
@media only screen and (max-width: 1024px) {
  .main-menu__col + .main-menu__col {
    margin-left: 100px;
  }
}
.main-menu__item {
  transition: color 0.1s linear 0s;
  will-change: color;
  color: #343434;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
  padding-bottom: 10px;
}
.main-menu__item:hover {
  color: #d24676;
}
.main-menu__item.active {
  color: #d24676;
}
.main-menu__item.active::after {
  content: '';
  background: #d24676;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
}
.main-menu__item + .main-menu__item {
  margin-left: 48px;
}
@media only screen and (max-width: 1024px) {
  .main-menu__item + .main-menu__item {
    margin-left: 24px;
  }
}
.main-menu__ico {
  width: 16px;
  height: 16px;
  color: #57aab9;
  position: absolute;
  right: 100%;
  top: 50%;
  pointer-events: none;
  padding: 3px;
  margin: -8px 16px 0 0;
}
@media only screen and (max-width: 1024px) {
  .main-menu__ico {
    margin-right: 4px;
  }
}
.logo {
  transform: translateX(-50%);
  transition: transform 0.1s linear 0s;
  will-change: transform;
  overflow: hidden;
  position: absolute;
  left: 50%;
  width: 248px;
  height: 80px;
}
.logo--small {
  transform: translateX(-50%) translateY(-25px);
}
.logo__img {
  transition: transform 0.1s linear 0s;
  will-change: transform;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: 9px;
  cursor: pointer;
}
.logo--small .logo__img {
  transform: translateX(-50%) translateY(25px);
}
.main-footer {
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.1));
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 56px;
  flex: none;
  position: relative;
  z-index: 2;
}
.main-footer__logo {
  bottom: 0;
}
.main-footer__logo::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(1, -1);
  content: '';
  background: no-repeat url(img/bg_forelock.svg);
}
.main-footer__link {
  transition: color 0.1s linear 0s;
  will-change: color;
  color: #97bdc4;
}
.main-footer__link:hover {
  color: #d24676;
}
.main-footer__link + .main-footer__link {
  margin-left: 16px;
}
.main-footer__col {
  position: absolute;
  z-index: 1;
  padding-top: 4px;
}
@media only screen and (max-width: 768px) {
  .main-footer__col {
    display: none;
  }
}
.main-footer__col:nth-child(1) {
  right: 50%;
  margin-right: 144px;
}
.main-footer__col:nth-child(2) {
  left: 50%;
  margin-left: 144px;
}
.main-footer__ico {
  width: 40px;
  height: 40px;
}
.btn {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  font-size: 15px;
  font-weight: normal;
  color: inherit;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  background: transparent;
  user-select: none;
  text-decoration: none;
  text-align: left;
  padding: 0;
  margin: 0;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.1));
  font-family: 'Nefelibata-SansCd', 'Roboto', sans-serif;
  transition: background-color 0.1s linear 0s, color 0.1s linear 0s, transform 0.1s linear 0s;
  will-change: background-color, color, transform;
  font-size: 28px;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
}
.btn:hover {
  transform: scale(1.05);
  color: #fff;
  background-color: #d75b85;
}
.btn:active {
  transform: scale(0.95);
  color: #fff;
  background-color: #cd3267;
}
.btn--default {
  border-radius: 16px;
  background: #fff;
  color: #57aab9;
  height: 48px;
  line-height: 40px;
  padding: 0 20px;
}
.btn--action {
  background: #d24676;
  color: #fff;
}
.btn--action,
.btn--large {
  border-radius: 24px;
  height: 64px;
  line-height: 50px;
  font-size: 38px;
  padding: 0 26px;
}
.btn--fluid {
  width: 100%;
}
.btn__text {
  transform: translateY(-4px);
  display: block;
}
.checkbox {
  display: inline-flex;
  align-items: center;
}
.checkbox__input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.checkbox__text {
  color: #676767;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.checkbox__text::before {
  width: 44px;
  height: 44px;
  border-radius: 16px;
  transition: background-size 0.1s linear 0s, border-color 0.1s linear 0s;
  will-change: background-size, border-color;
  content: '';
  background: no-repeat rgba(0, 0, 0, 0.1) url(img/ico_tick.svg) 50% 50% / 0 0;
  border: 2px solid rgba(0, 0, 0, 0.2);
  margin-right: 10px;
}
.checkbox:hover .checkbox__text::before {
  border-color: rgba(0, 0, 0, 0.3);
}
.checkbox__input:checked + .checkbox__text::before {
  background-size: 24px 24px;
}
.checkbox__text-align {
  transform: translateY(-4px);
}
.input {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  border-radius: 16px;
  transition: background-color 0.1s linear 0s, border-color 0.1s linear 0s;
  will-change: background-color, border-color;
  font-size: 24px;
  background: rgba(0, 0, 0, 0.1);
  border: 2px solid transparent;
  width: 100%;
  height: 48px;
  padding: 0 24px;
}
.input:focus {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.1);
}
.input:invalid,
.input.error {
  border-color: #d24676;
}
.textarea {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  border-radius: 16px;
  transition: background-color 0.1s linear 0s, border-color 0.1s linear 0s;
  will-change: background-color, border-color;
  font-size: 24px;
  background: rgba(0, 0, 0, 0.1);
  border: 2px solid transparent;
  width: 100%;
  height: 96px;
  padding: 4px 22px 16px;
  resize: vertical;
}
.textarea:focus {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.1);
}
.textarea:invalid,
.textarea.error {
  border-color: #d24676;
}
.autocomplete {
  position: relative;
}
.autocomplete__field-container {
  position: relative;
}
.autocomplete__input {
  border-radius: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 28px;
  background: #fff;
  line-height: 3;
  height: 64px;
  padding: 0 64px 10px 38px;
}
.autocomplete__input::-webkit-input-placeholder {
  line-height: 3;
}
.autocomplete__input::-moz-placeholder {
  line-height: 3;
}
.autocomplete__input:-ms-input-placeholder {
  line-height: 3;
}
.autocomplete__action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0;
}
.autocomplete__dropdown {
  border-radius: 24px;
  background: #fff;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 16px;
  display: none;
}
.active .autocomplete__dropdown {
  display: block;
  animation: dropdown-show 0.3s linear forwards;
}
.autocomplete__scrollable-container {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  max-height: 288px;
  padding: 0 70px 0 40px;
  margin-right: -30px;
}
.autocomplete__item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
}
.autocomplete__item + .autocomplete__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
@keyframes dropdown-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.breadcrumbs {
  font-family: 'CenturyGothic', sans-serif;
  margin-bottom: 38px;
}
@media only screen and (max-width: 768px) {
  .breadcrumbs {
    display: none;
  }
}
.breadcrumbs__item {
  border-radius: 8px;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s;
  will-change: color, background-color;
  background-color: rgba(255, 255, 255, 0.2);
  color: #676767;
  position: relative;
  display: inline-block;
  align-items: center;
  height: 26px;
  padding: 0 8px;
}
.breadcrumbs__item:not(:last-child):hover {
  color: #d24676;
  background-color: rgba(255, 255, 255, 0.4);
}
.breadcrumbs__item:last-child {
  color: #d24676;
  cursor: default;
}
.breadcrumbs__item + .breadcrumbs__item {
  margin-left: 24px;
}
.breadcrumbs__item + .breadcrumbs__item::before {
  transform: translateY(-50%);
  border: solid transparent;
  border-width: 4px 0 4px 5px;
  border-left-color: rgba(0, 0, 0, 0.1);
  content: '';
  position: absolute;
  right: 100%;
  top: 50%;
  margin-right: 8px;
}
.breadcrumbs__ico-dots {
  width: 24px;
  height: 24px;
}
.breadcrumbs a,
.breadcrumbs a:hover {
  color: inherit;
}
.pagination {
  text-align: center;
  margin-top: 48px;
}
.pagination__btn--arrow {
  width: 40px;
  padding: 0;
}
.pagination__btn.active {
  background-color: #d24676;
  color: #fff;
  cursor: default;
}
.pagination__btn.active:hover {
  transform: none;
}
.pagination__btn + .pagination__btn {
  margin-left: 8px;
}
.offer-card {
  font-family: 'Nefelibata-SansCd', 'Roboto', sans-serif;
  display: block;
  font-size: 28px;
  color: #343434;
  text-align: center;
}
.offer-card__img-container {
  transition: transform 0.1s linear 0s;
  will-change: transform;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto 8px;
}
.offer-card:hover .offer-card__img-container {
  transform: scale(1.1);
}
.offer-card__smooth-triangle {
  transform: translate(-50%, -50%);
  width: 168px;
  height: 168px;
  transition: transform 0.1s linear 0s;
  will-change: transform;
  position: absolute;
  top: 50%;
  left: 50%;
}
.offer-card:hover .offer-card__smooth-triangle {
  transform: translate(-50%, -50%) scale(0.85);
}
.offer-card__img {
  position: relative;
  z-index: 1;
  max-height: 100%;
}
.owl-carousel .owl-item .offer-card__img {
  width: auto;
}
.offer-card__badge-new,
.offer-card__badge-discount {
  transition: transform 0.1s linear 0s;
  will-change: transform;
  position: absolute;
  right: 0;
  z-index: 1;
}
.offer-card__badge-new {
  width: 86px;
  height: 86px;
  top: 0;
  line-height: 80px;
}
.offer-card:hover .offer-card__badge-new {
  transform: translateY(15px) scale(0.9);
}
.offer-card__badge-discount {
  bottom: 0;
  width: 99px;
  height: 69px;
  line-height: 63px;
}
.offer-card:hover .offer-card__badge-discount {
  transform: translateY(-15px) scale(0.9);
}
.offer-card__title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: color 0.1s linear 0s;
  will-change: color;
}
.offer-card:hover .offer-card__title {
  color: #d24676;
}
.badge-new,
.badge-discount {
  font-size: 28px;
  display: flex;
  justify-content: center;
  background: no-repeat 50% 50% / 100% auto;
}
.badge-new {
  font-family: 'Nefelibata-SansCd', 'Roboto', sans-serif;
  transform: rotate(-15deg);
  color: #fff;
  text-transform: uppercase;
  background-image: url(img/bg_badge.svg);
}
.badge-discount {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  color: #343434;
  background-image: url(img/bg_discount.svg);
}
.badge-discount__text {
  transform: rotate(10deg);
}
.price {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  color: #d24676;
  font-size: 38px;
  line-height: 1;
  text-align: center;
  position: relative;
  display: inline;
}
.price__old {
  color: #676767;
  font-size: 24px;
  white-space: nowrap;
  position: absolute;
  right: 100%;
  top: 0.45em;
  bottom: 5px;
  margin-right: 8px;
}
.price__old::after {
  content: '';
  background-color: #676767;
  position: absolute;
  top: calc(50% + 0.05em);
  left: 0;
  right: 0;
  height: 1.5px;
}
.about-page {
  text-align: center;
  position: relative;
  padding: 88px 0 188px;
}
@media only screen and (max-width: 768px) {
  .about-page {
    padding-bottom: 48px;
  }
}
.about-page::after {
  border-radius: 16px;
  background: url(img/bg_mole.svg);
  content: '';
  position: absolute;
  bottom: 58px;
  right: -10px;
  width: 247px;
  height: 219px;
}
@media only screen and (max-width: 768px) {
  .about-page::after {
    position: static;
    display: block;
    margin: 48px auto 0;
  }
}
.about-page__content {
  margin-bottom: 48px;
}
.about-page__content p + p {
  margin-top: 24px;
}
.about-page__content h2,
.about-page__content h3 {
  margin-bottom: 32px;
}
p + .about-page__content h2,
p + .about-page__content h3 {
  margin-top: 24px;
}
.about-page__main-image {
  width: 100%;
  max-width: 656px;
  margin: 0 auto 32px;
}
.about-page__content {
  font-size: 24px;
  color: #676767;
}
.about-page__gallery {
  margin-top: 32px;
}
.about-page__gallery + p {
  margin-top: 32px;
}
.all-toys {
  background: #ace6f4 url(img/bg-pattern--white.svg);
  padding: 40px 0 88px;
  min-height: calc(100vh - 56px - 56px);
}
.all-toys__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -48px;
}
.all-toys__item {
  width: 33.333%;
  margin-top: 48px;
}
@media only screen and (max-width: 1024px) {
  .all-toys__item {
    width: 50%;
  }
}
@media only screen and (max-width: 425px) {
  .all-toys__item {
    width: 100%;
  }
}
.all-toys__main-container {
  display: flex;
}
.all-toys__sidebar {
  flex: none;
  width: 224px;
  margin-right: 40px;
}
@media only screen and (max-width: 768px) {
  .all-toys__sidebar {
    display: none;
  }
}
.all-toys__content {
  flex: 1;
  min-width: 0;
}
.filter {
  border-radius: 24px;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.1));
  position: relative;
  background: #fff;
  flex: none;
  padding: 24px 0;
}
.filter--mobile {
  display: none;
  flex-direction: column;
  max-height: 100%;
}
.filter--mobile.shown {
  display: flex;
}
.filter::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 24px;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}
.filter__main-menu {
  display: flex;
  overflow: hidden;
  border: solid rgba(0, 0, 0, 0.1);
  border-width: 1px 0;
}
.filter__main-menu-btn {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  transition: background-color 0.1s linear 0s;
  will-change: background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  flex: 1;
  position: relative;
  cursor: pointer;
  height: 46px;
}
.filter__main-menu-btn.active,
.filter__main-menu-btn:hover {
  color: #d24676;
  background: rgba(0, 0, 0, 0.1);
}
.filter__main-menu-btn.active::before,
.filter__main-menu-btn:hover::before {
  content: none;
}
.filter__main-menu-btn.active {
  cursor: default;
}
.filter__main-menu-btn.active::after {
  height: 4px;
}
.filter__main-menu-btn::before {
  content: '';
  width: 1px;
  position: absolute;
  top: 7px;
  bottom: 7px;
  left: -1px;
  background: #e5e5e5;
}
.filter__main-menu-btn::after {
  transition: height 0.1s linear 0s;
  will-change: height;
  background: #d24676;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
}
.filter__badge-new {
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 30px;
}
.filter__badge-discount {
  font-size: 15px;
  width: 44px;
  height: 24px;
  line-height: 20px;
}
.filter__scroll-container {
  -webkit-overflow-scrolling: touch;
  flex: 1;
  overflow-y: auto;
}
.filter__item {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  transition: border-width 0.1s linear 0s, color 0.1s linear 0s;
  will-change: border-width, color;
  color: #343434;
  font-size: 21px;
  display: flex;
  cursor: pointer;
  position: relative;
  user-select: none;
  width: 100%;
  height: 48px;
  line-height: 40px;
  padding: 0 16px 0 24px;
}
.filter__item:hover {
  color: #d24676;
}
button.filter__item {
  padding-right: 30px;
}
.filter__item.current {
  color: #d24676;
}
.filter__item.current::before {
  width: 4px;
}
.filter__item::before {
  transition: width 0.1s linear 0s;
  will-change: width;
  background-color: #d24676;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
}
.filter__text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  margin-right: 8px;
}
.filter__count {
  font-family: 'CenturyGothic', sans-serif;
  color: #ccc;
  font-size: 13px;
  margin-left: auto;
}
.filter__arrow {
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  transition: transform 0.1s linear 0s;
  will-change: transform;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  right: 16px;
}
.open .filter__arrow {
  transform: translateY(-50%) rotate(180deg);
}
.filter__submenu {
  font-family: 'CenturyGothic', sans-serif;
  transition: padding 0.1s linear 0s;
  will-change: padding;
  font-size: 15px;
  background: rgba(0, 0, 0, 0.05);
  border: 0 solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0;
}
.open .filter__submenu {
  padding: 5px 0 9px;
  border-width: 1px 0;
}
.filter__sublink {
  transition: height 0.1s linear 0s, opacity 0.1s linear 0s, color 0.1s linear 0s;
  will-change: height, opacity, color;
  color: #676767;
  display: flex;
  align-items: center;
  border-left: 4px solid transparent;
  user-select: none;
  overflow: hidden;
  height: 0;
  padding: 0 16px 0 28px;
  opacity: 0;
}
.filter__sublink:hover {
  color: #d24676;
}
.filter__sublink.current {
  color: #d24676;
  border-color: #d24676;
}
.open .filter__sublink {
  height: 40px;
  opacity: 1;
}
.mobile-filter-control {
  position: fixed;
  bottom: 32px;
  left: 24px;
  right: 24px;
  z-index: 10;
  justify-content: center;
  display: none;
}
@media only screen and (max-width: 768px) {
  .mobile-filter-control {
    display: flex;
  }
}
.mobile-filter-control__container {
  border-radius: 24px;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.1));
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;
}
.mobile-filter-control__container--initial {
  width: 100%;
}
.mobile-filter-control__btn-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transform: translateY(-4px);
  line-height: 30px;
  display: block;
  max-width: 100%;
  padding: 0 16px;
}
.mobile-filter-control__arrow {
  transform: translateY(-50%) scale(1, -1);
  width: 16px;
  height: 16px;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  right: 16px;
}
.mobile-filter-control__category {
  position: relative;
  margin-right: 21px;
}
.mobile-filter-control__category::before {
  transform: translateY(-50%);
  border: solid transparent;
  border-width: 4px 0 4px 5px;
  border-left-color: rgba(0, 0, 0, 0.1);
  content: '';
  position: absolute;
  left: 100%;
  top: 50%;
  margin: 4px 0 0 6px;
}
.mobile-filter-control__subcategory {
  color: #d24676;
}
.mobile-filter-control__open-filter-btn {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  flex: 1;
  overflow: hidden;
  height: 48px;
}
.mobile-filter-control__clear-filter-btn {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  position: relative;
}
.mobile-filter-control__clear-filter-btn::after {
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.1);
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 24px;
}
.mobile-filter-control__ico-cross {
  width: 13px;
  height: 13px;
  color: rgba(0, 0, 0, 0.1);
}
.delivery {
  text-align: center;
  padding: 88px 0 105px;
}
.delivery__blue-section {
  padding-bottom: 96px;
  margin-bottom: 50px;
}
.delivery__map {
  margin: 0 auto 64px;
}
.delivery__contact-btn {
  width: 216px;
}
.delivery__scissors {
  width: 103px;
  height: 103px;
  position: absolute;
  left: 50%;
}
@media only screen and (max-width: 768px) {
  .delivery__scissors {
    display: none;
  }
}
.delivery__scissors--top {
  transform: translateX(345px);
  color: #ace6f4;
  bottom: 100%;
}
@media only screen and (max-width: 1024px) {
  .delivery__scissors--top {
    transform: translateX(172px);
  }
}
.delivery__scissors--bottom {
  transform: translateX(-448px) scale(-1, 1);
  color: #fff;
  bottom: 0;
}
@media only screen and (max-width: 1024px) {
  .delivery__scissors--bottom {
    transform: translateX(-224px);
  }
}
.delivery__packing {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .delivery__packing {
    flex-direction: column;
    align-items: center;
  }
}
.delivery__packing-variant {
  height: 160px;
  width: auto;
}
.delivery__packing-variant + .delivery__packing-variant {
  margin-left: 80px;
}
@media only screen and (max-width: 768px) {
  .delivery__packing-variant + .delivery__packing-variant {
    margin: 48px 0 0;
  }
}
.profits {
  font-size: 21px;
  display: flex;
  max-width: 888px;
  margin: 0 auto 95px;
}
@media only screen and (max-width: 768px) {
  .profits {
    flex-direction: column;
    align-items: center;
  }
}
.profits__item {
  font-size: 24px;
  width: 100%;
  max-width: 264px;
}
.profits__item::before {
  font-size: 0;
  display: block;
  margin-bottom: 32px;
}
.profits__item--chat::before {
  content: url(img/ico_chat.svg);
}
.profits__item--tailoring::before {
  content: url(img/ico_tailoring.svg);
}
.profits__item--shipping::before {
  content: url(img/ico_shipping.svg);
}
.profits__item + .profits__item {
  margin-left: 48px;
}
@media only screen and (max-width: 768px) {
  .profits__item + .profits__item {
    margin: 63px 0 0;
  }
}
.profits__text {
  margin-bottom: 32px;
}
.profits__btn {
  width: 160px;
}
.delivery-autocomplete {
  max-width: 480px;
  margin: 0 auto 48px;
}
.delivery-autocomplete__ico-path {
  transform: translateY(1px);
  width: 16px;
  height: 16px;
  color: #d24676;
}
.btn:hover .delivery-autocomplete__ico-path {
  color: #fff;
}
.delivery-autocomplete__item {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  color: #343434;
  font-size: 28px;
  display: flex;
  width: 100%;
}
.delivery-autocomplete__city {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: color 0.1s linear 0s;
  will-change: color;
  flex: 1;
  min-width: 0;
  padding-bottom: 6px;
}
.delivery-autocomplete__item:hover .delivery-autocomplete__city {
  color: #d24676;
}
.delivery-autocomplete__time {
  color: #57aab9;
  margin-left: 16px;
  padding-bottom: 6px;
}
.main-page {
  text-align: center;
  position: relative;
  padding: 50px 0 88px;
}
.main-page::before {
  background: repeat-x 50% 0;
  position: absolute;
  left: 0;
  right: 0;
  height: 32px;
  background-image: url(img/bg_wave--white.svg);
  content: '';
  bottom: 100%;
  z-index: 1;
}
.main-page__section {
  position: relative;
}
.main-page__section + .main-page__section {
  margin-top: 97px;
}
.main-page__section + .main-page__section--blue {
  margin-top: 80px;
}
@media only screen and (max-width: 768px) {
  .main-page__section + .main-page__section--steps,
  .main-page__section + .main-page__section--history {
    margin-top: 48px;
  }
}
.main-page__title {
  margin-bottom: 15px;
}
.main-page__subtitle {
  margin-bottom: 48px;
}
.main-slider {
  overflow: hidden;
  flex: none;
  position: relative;
  z-index: 2;
  margin-bottom: 12px;
}
.main-slider__item {
  height: 400px;
}
.main-slider__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.main-slider__content {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 0 40px;
}
.main-slider__title {
  color: #fff;
  margin-bottom: 16px;
}
.main-slider__additional-img {
  width: 240px;
  height: 240px;
  transform: translateY(50%);
  position: absolute;
  left: 0;
  bottom: 100%;
}
@media only screen and (max-width: 768px) {
  .main-slider__additional-img {
    display: none;
  }
}
.main-slider .owl-dots {
  bottom: 12px;
}
.toy-categories {
  max-width: 930px;
  margin: 0 auto;
}
.toy-categories__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -48px;
}
.toy-categories__item {
  width: 25%;
  margin-top: 48px;
}
@media only screen and (max-width: 1024px) {
  .toy-categories__item {
    width: 33.333%;
  }
  .toy-categories__item:nth-child(n+4) {
    display: none;
  }
  .show-all .toy-categories__item {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .toy-categories__item {
    width: 100%;
  }
}
.toy-categories__show-all-btn {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .toy-categories__show-all-btn {
    display: inline-block;
    margin-top: 48px;
  }
}
.show-all + .toy-categories__show-all-btn {
  display: none;
}
.steps__list {
  display: flex;
  justify-content: center;
  counter-reset: step;
  margin-bottom: 48px;
}
@media only screen and (max-width: 768px) {
  .steps__list {
    flex-direction: column;
    align-items: center;
  }
}
.steps__item {
  font-size: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .steps__item {
    width: fit-content;
  }
}
.steps__item::before {
  font-family: 'Nefelibata-SansCd', 'Roboto', sans-serif;
  color: #97bdc4;
  font-size: 36px;
  counter-increment: step;
  content: counter(step) '.';
  position: absolute;
  left: 0;
  top: 2px;
}
@media only screen and (max-width: 768px) {
  .steps__item::after {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .steps__item--step-1 {
    padding-top: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .steps__item--step-2 {
    padding-top: 27px;
  }
}
.steps__item--step-2::after,
.steps__item--step-3::after {
  transform: translateY(-50%);
  content: '';
  background: no-repeat url(img/img_steps-arrow.png) 50% 50% / 88px 64px;
  position: absolute;
  top: 50%;
  right: 100%;
  width: 88px;
  height: 64px;
}
@media only screen and (max-width: 768px) {
  .steps__item--step-3 {
    padding-top: 25px;
  }
}
.steps__item--step-3::after {
  transform: translateY(-50%) scale(1, -1);
}
.steps__item + .steps__item {
  margin-left: 88px;
}
@media only screen and (max-width: 768px) {
  .steps__item + .steps__item {
    margin: 32px 0 0;
  }
}
.steps__item .offer-card__img-container {
  margin-bottom: 17px;
  height: 202px;
}
.steps__img {
  width: 224px;
  margin-bottom: 17px;
}
@media only screen and (max-width: 768px) {
  .steps__btn {
    width: 100%;
    max-width: 256px;
  }
}
@media only screen and (max-width: 768px) {
  .steps__toy-text {
    display: none;
  }
}
.about {
  position: relative;
}
.about__content {
  display: flex;
  align-items: flex-start;
  padding-top: 44px;
  margin: 0 auto 48px;
}
@media only screen and (max-width: 768px) {
  .about__content {
    display: block;
    max-width: 257px;
  }
}
.about__elena {
  width: 432px;
  height: 432px;
  height: auto;
  margin: 4px 32px 0 0;
}
@media only screen and (max-width: 768px) {
  .about__elena {
    margin-bottom: 48px;
  }
}
.about__text {
  font-family: 'CenturyGothic', sans-serif;
  color: #676767;
  line-height: 23px;
  text-align: left;
  line-height: 1.55;
}
.about__text p + p {
  margin-top: 22px;
}
.about__hello {
  font-family: 'Nefelibata-SansCd', 'Roboto', sans-serif;
  font-size: 28px;
  color: #343434;
  margin-bottom: 6px;
}
@media only screen and (max-width: 768px) {
  .about__hello {
    line-height: 1.25;
    margin-bottom: 8px;
  }
}
.about__signature {
  width: 298px;
  margin-top: 27px;
}
.about__scissors {
  transform: scale(-1, 1);
  color: #ace6f4;
  position: absolute;
  bottom: 100%;
  left: 76px;
  width: 100px;
  height: 90px;
  margin-bottom: 60px;
}
@media only screen and (max-width: 768px) {
  .about__scissors {
    display: none;
  }
}
.reviews {
  text-align: center;
  max-width: 640px;
  padding: 88px 0;
  margin: 0 auto;
}
.reviews__btn-container {
  margin-bottom: 72px;
}
.reviews__list {
  text-align: left;
  margin-bottom: 34px;
}
.reviews__item {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .reviews__item {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}
.reviews__item + .reviews__item {
  margin-top: 23px;
}
.reviews__img-container {
  width: 96px;
  height: 96px;
  position: relative;
  flex: none;
  margin: 8px 32px 0 0;
  background: url(img/ico_no-avatar.svg) 50% / cover;
}
.reviews__img-container::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: url(img/bg_invert-triangle.svg) 50% / cover;
}
.reviews__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.reviews__social {
  width: 40px;
  height: 40px;
  color: #57aab9;
  position: absolute;
  bottom: -7px;
  right: -7px;
  z-index: 1;
}
.reviews__name {
  color: #57aab9;
  font-size: 21px;
  margin-bottom: 11px;
}
.reviews__text {
  font-family: 'CenturyGothic', sans-serif;
  color: #676767;
  line-height: 23px;
  margin-bottom: 6px;
}
.reviews__text p + p {
  margin-top: 22px;
}
@media only screen and (max-width: 768px) {
  .reviews__marks {
    justify-content: center;
  }
}
.reviews__add-review {
  text-align: center;
  margin-top: 80px;
}
.reviews__btn-title {
  margin-bottom: 16px;
}
.reviews__this-text {
  font-size: 28px;
  margin-bottom: 16px;
}
.marks {
  display: flex;
}
.marks__item {
  width: 24px;
  height: 24px;
  color: #d24676;
  padding: 4px;
}
.active-marks {
  display: flex;
  flex-direction: row-reverse;
}
.active-marks__item:hover .active-marks__heart {
  display: block;
}
.active-marks__item:hover .active-marks__heart--empty {
  display: none;
}
.active-marks__item:hover ~ .active-marks__item .active-marks__heart,
.active-marks__input:checked ~ .active-marks__item .active-marks__heart {
  display: block;
}
.active-marks__item:hover ~ .active-marks__item .active-marks__heart--empty,
.active-marks__input:checked ~ .active-marks__item .active-marks__heart--empty {
  display: none;
}
.active-marks__input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.active-marks__heart {
  width: 40px;
  height: 40px;
  color: #d24676;
  cursor: pointer;
  padding: 6px;
  display: none;
}
.active-marks__heart--empty {
  display: block;
}
.toy-card {
  padding-top: 40px;
}
.toy-card__slider-container {
  margin-bottom: 24px;
}
.toy-card__slider-container .common-slider__slide {
  height: 480px;
}
@media only screen and (max-width: 768px) {
  .toy-card__slider-container .common-slider__slide {
    height: 300px;
  }
}
.toy-card__show-fullscreen {
  transition: color 0.1s linear 0s;
  will-change: color;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 8px;
  right: 16px;
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .toy-card__show-fullscreen {
    top: 6px;
    right: 11px;
  }
}
.toy-card__show-fullscreen:hover {
  color: rgba(0, 0, 0, 0.2);
}
.toy-card__ico-eye {
  width: 32px;
  height: 32px;
}
@media only screen and (max-width: 768px) {
  .toy-card__ico-eye {
    width: 24px;
    height: 24px;
  }
}
.toy-card__badge-discount {
  position: absolute;
  bottom: -9px;
  right: -13px;
  z-index: 1;
  width: 99px;
  height: 59px;
  line-height: 52px;
}
@media only screen and (max-width: 768px) {
  .toy-card__badge-discount {
    font-size: 20px;
    width: 77px;
    height: 48px;
    line-height: 44px;
  }
}
.toy-card__price {
  text-align: center;
  width: fit-content;
  margin: 0 auto 16px;
}
.toy-card__price .price {
  font-size: 76px;
  display: inline-block;
}
.toy-card__price .price__old {
  font-size: 38px;
  height: 46px;
  margin-right: 24px;
}
@media only screen and (max-width: 768px) {
  .toy-card__price .price__old {
    display: block;
    position: relative;
    right: 0;
    top: 0;
    width: fit-content;
    margin: 0 auto;
  }
}
.toy-card__content {
  max-width: 600px;
  margin: 0 auto 96px;
}
.toy-card__order-btn {
  margin-bottom: 24px;
}
.toy-card__main-title {
  margin-bottom: 8px;
}
.toy-card__subtitle {
  margin-bottom: 24px;
}
@media only screen and (max-width: 768px) {
  .toy-card__subtitle {
    margin-bottom: 32px;
  }
}
.toy-card__text {
  font-family: 'CenturyGothic', sans-serif;
  color: #676767;
  line-height: 1.5;
  margin-bottom: 24px;
}
.toy-card__text p + p {
  margin-top: 20px;
}
.toy-card__params {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
@media only screen and (max-width: 768px) {
  .toy-card__params {
    display: block;
  }
}
.toy-card__param {
  text-align: center;
}
.toy-card__param + .toy-card__param {
  margin-left: 86px;
}
@media only screen and (max-width: 768px) {
  .toy-card__param + .toy-card__param {
    margin: 24px 0 0;
  }
}
.toy-card__param-title {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  font-size: 21px;
  color: #676767;
  margin: 0 0 16px;
}
.toy-card__big-text {
  font-family: 'Nefelibata-PenSans', 'Roboto', sans-serif;
  color: #d24676;
  font-size: 24px;
}
@media only screen and (max-width: 768px) {
  .toy-card__big-text {
    text-align: center;
    line-height: 1.25;
  }
}
.toys-materials {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: fit-content;
  margin: -30px 0 0 -30px;
}
@media only screen and (max-width: 768px) {
  .toys-materials {
    width: calc(100% + 30px);
  }
}
.toys-materials__item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 21px;
  max-width: 64px;
  margin: 30px 0 0 30px;
}
.toys-materials__img {
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin: 0 auto 8px;
}
.toys-sizes {
  position: relative;
  width: 60px;
  margin: 0 auto;
  padding-bottom: 25px;
}
.toys-sizes__size {
  font-size: 21px;
  position: absolute;
}
.toys-sizes__size--width {
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
}
.toys-sizes__size--height {
  transform: translateY(-50%);
  top: 50%;
  right: 100%;
  margin-right: 16px;
}
.toys-more {
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .toys-more {
    padding: 22px 0 111px;
  }
}
.toys-more::after {
  content: none;
}
.toys-more__title {
  text-transform: uppercase;
  margin-bottom: 22px;
}
.toys-more__slider {
  margin-bottom: 58px;
}
@media only screen and (max-width: 768px) {
  .toys-more__slider {
    margin-bottom: 50px;
  }
}
.toys-more__slider .owl-dots {
  bottom: -145px;
}
.toy-story {
  padding-top: 88px;
}
.toy-story__main-title {
  text-align: center;
  margin-bottom: 21px;
}
.toy-story__subtitle {
  text-align: center;
}
.toy-story__gallery {
  margin-bottom: 40px;
}
.toy-story__text + .toy-story__gallery {
  margin-top: 32px;
}
.toy-story__text {
  font-family: 'CenturyGothic', sans-serif;
  color: #676767;
  line-height: 23px;
  max-width: 640px;
  margin: 0 auto;
}
.toy-story__text p + p {
  margin-top: 22px;
}
.toy-story__elena {
  transform: translateX(-24px);
  width: 100%;
  max-width: 432px;
  margin: 32px auto 40px;
}
@media only screen and (max-width: 768px) {
  .toy-story__elena {
    transform: none;
  }
}
.toy-story img {
  border-radius: 16px;
}
.toys-stories {
  padding: 88px 0 62px;
}
.more-stories {
  text-align: center;
  padding-top: 65px;
  margin-top: 80px;
}
.more-stories::after {
  content: none;
}
.more-stories__subtitle {
  margin-bottom: 16px;
}
.toys-stories-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -32px 0 48px -32px;
}
@media only screen and (max-width: 768px) {
  .toys-stories-list {
    display: block;
    margin-top: -24px;
  }
}
.toys-stories-list__item {
  transition: filter 0.1s linear 0s;
  will-change: filter;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.1));
  color: #343434;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: calc(33.333% - 32px);
  max-width: 304px;
  margin: 32px 0 0 32px;
}
@media only screen and (max-width: 768px) {
  .toys-stories-list__item {
    width: calc(100% - 32px);
    max-width: none;
    margin-top: 24px;
  }
}
.toys-stories-list__item:hover {
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
}
.toys-stories-list__img-container {
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: relative;
  height: 210px;
}
.toys-stories-list__img-container::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(125deg, #ff9e9e, #6cd8b0);
  content: '';
  opacity: 0.2;
}
.toys-stories-list__img {
  position: relative;
  max-height: 100%;
}
.toys-stories-list__badge-new {
  width: 86px;
  height: 86px;
  transition: transform 0.1s linear 0s;
  will-change: transform;
  position: absolute;
  right: -13px;
  top: -13px;
  z-index: 1;
  line-height: 80px;
}
.toys-stories-list__item:hover .toys-stories-list__badge-new {
  transform: scale(0.9);
}
.toys-stories-list__content {
  font-family: 'CenturyGothic', sans-serif;
  border-radius: 0 0 16px 16px;
  background: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
}
.toys-stories-list__title {
  font-family: 'Nefelibata-SansCd', 'Roboto', sans-serif;
  transition: color 0.1s linear 0s;
  will-change: color;
  font-size: 28px;
  margin-bottom: 20px;
}
.toys-stories-list__item:hover .toys-stories-list__title {
  color: #d24676;
}
.toys-stories-list__text {
  line-height: 1.55;
  margin-bottom: 16px;
}
@media only screen and (max-width: 768px) {
  .toys-stories-list__text {
    margin-bottom: 28px;
  }
}
.toys-stories-list__time {
  font-size: 13px;
  color: #97bdc4;
  margin-top: auto;
}
.slider-arrow {
  width: 50px;
  height: 50px;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.1));
  color: #fff;
}
.slider-arrow--right {
  transform: rotate(180deg);
}
.owl-prev,
.owl-next {
  width: 64px;
  height: 64px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
}
@media only screen and (max-width: 1024px) {
  .owl-prev,
  .owl-next {
    display: none;
  }
}
.owl-dots {
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  display: flex;
  justify-content: center;
}
.owl-dot {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.owl-dot + .owl-dot {
  margin-left: 4px;
}
.owl-dot.active {
  cursor: default;
}
.owl-dot.active span {
  background-color: #d24676;
}
.owl-dot span {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.more-toys-slider {
  position: relative;
}
@media only screen and (max-width: 768px) {
  .more-toys-slider {
    margin: 0 -16px;
  }
}
.more-toys-slider__slider {
  max-width: 920px;
  margin: -20px auto 48px;
}
.more-toys-slider__item {
  padding: 0 16px;
}
.more-toys-slider__scissors {
  color: #fff;
  position: absolute;
  top: 100%;
  right: 76px;
  width: 100px;
  height: 90px;
  margin-top: -15px;
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .more-toys-slider__scissors {
    display: none;
  }
}
.more-toys-slider .owl-stage-outer {
  padding-top: 20px;
}
.more-toys-slider .owl-prev {
  right: 100%;
  margin: -22px -28px 0 0;
}
.more-toys-slider .owl-next {
  left: 100%;
  margin: -22px 0 0 -28px;
}
.more-toys-slider .owl-dots {
  top: 100%;
  margin-top: 120px;
}
.common-slider {
  position: relative;
}
.common-slider__slider {
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.1));
  border-radius: 16px;
  overflow: hidden;
}
.common-slider__slider .owl-dots {
  bottom: 16px;
}
.common-slider__slide {
  overflow: hidden;
  position: relative;
  height: 300px;
}
.common-slider--with-gradient .common-slider__slide::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background: linear-gradient(to right, #ff9e9e, #6cd8b0);
  opacity: 0.2;
  pointer-events: none;
}
.common-slider__slide img {
  object-fit: cover;
  height: 100%;
}
.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding: 16px;
  display: none;
}
@media only screen and (max-width: 768px) {
  .overlay {
    padding-top: 72px;
  }
}
.overlay.active {
  display: block;
  animation: overlay-show 0.3s linear forwards;
}
.overlay__ico-cross {
  width: 24px;
  height: 24px;
  color: #fff;
  position: absolute;
  top: 16px;
  right: 16px;
  pointer-events: none;
}
.overlay__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .overlay__content {
    justify-content: flex-end;
    align-items: stretch;
  }
}
@keyframes overlay-show {
  0% {
    backdrop-filter: blur(0);
  }
  100% {
    backdrop-filter: blur(4px);
  }
}
.mobile-menu {
  border-radius: 24px;
  display: none;
  background: #fff;
  padding: 24px 0;
}
.mobile-menu.shown {
  display: block;
}
.mobile-menu__item {
  color: #343434;
  font-size: 21px;
  display: flex;
  align-items: center;
  position: relative;
  height: 48px;
  padding: 0 24px;
}
.mobile-menu__item:first-child,
.mobile-menu__item:last-child {
  border: 0 solid rgba(0, 0, 0, 0.1);
}
.mobile-menu__item:first-child {
  border-top-width: 1px;
}
.mobile-menu__item:last-child {
  border-bottom-width: 1px;
}
.mobile-menu__item.active {
  color: #d24676;
}
.mobile-menu__item.active::before {
  background: #d24676;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
}
.contacts {
  border-radius: 24px;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.1));
  display: none;
  color: #57aab9;
  background: #fff;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 18px 30px 26px;
}
.contacts.shown {
  display: flex;
}
.contacts__title {
  font-family: 'Nefelibata-SansCd', 'Roboto', sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.contacts__icons {
  display: flex;
  flex-wrap: wrap;
  width: 216px;
  margin: -24px 0 24px -24px;
}
.contacts__social,
.contacts__tel {
  transition: color 0.1s linear 0s;
  will-change: color;
  color: #57aab9;
}
.contacts__social:hover,
.contacts__tel:hover {
  color: #d24676;
}
.contacts__social {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 0 24px;
}
.contacts__ico-social {
  width: 34px;
  height: 34px;
}
.contacts__tel {
  font-size: 38px;
}
.order-form {
  display: none;
  flex-direction: column;
  align-items: center;
}
.order-form.shown {
  display: flex;
}
.order-form__form {
  border-radius: 24px;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.1));
  background: #fff;
  padding: 22px 24px 32px;
}
@media only screen and (max-width: 768px) {
  .order-form__form {
    width: 100%;
  }
}
.order-form__row + .order-form__row {
  margin-top: 16px;
}
.order-form__label {
  font-family: 'Nefelibata-SansCd', 'Roboto', sans-serif;
  color: #57aab9;
  font-size: 28px;
  display: block;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.order-form__input {
  height: 48px;
}
.order-form__btn {
  margin-top: 32px;
}
.order-form__social {
  display: flex;
  flex-wrap: wrap;
  width: 240px;
  padding-top: 16px;
  margin-left: -20px;
}
@media only screen and (max-width: 768px) {
  .order-form__social {
    display: none;
  }
}
.order-form__social-item {
  color: #fff;
  margin: 16px 0 0 20px;
}
.order-form__ico-social {
  width: 40px;
  height: 40px;
}
.success-sent-message {
  display: none;
}
.success-sent-message.shown {
  display: block;
}
.success-sent-message__message {
  border-radius: 24px;
  background: #fff;
  font-size: 28px;
  text-align: center;
  position: relative;
  min-width: 320px;
  padding: 32px 32px 40px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 768px) {
  .success-sent-message__message {
    min-width: inherit;
    margin-bottom: 150px;
  }
}
.success-sent-message__message::before {
  transform: translateX(-50%);
  content: url(img/ico_success-message.svg);
  font-size: 0;
  position: absolute;
  left: 50%;
  bottom: 100%;
  margin-bottom: -32px;
}
.fullscreen-gallery {
  border-radius: 24px;
  display: none;
  background: #fff;
  position: relative;
}
.fullscreen-gallery.shown {
  display: block;
}
@media only screen and (max-width: 768px) {
  .fullscreen-gallery {
    border-radius: 0px;
    background: transparent;
    margin: 0 -16px -16px;
  }
}
.fullscreen-gallery__fotorama {
  border-radius: 24px;
  max-width: 688px;
}
@media only screen and (max-width: 768px) {
  .fullscreen-gallery__fotorama {
    border-radius: 0px;
    max-width: none;
  }
}
.fullscreen-gallery__fullscreen-btn,
.fullscreen-gallery__leave-fullscreen-btn {
  transition: background-color 0.1s linear 0s;
  will-change: background-color;
  width: 48px;
  height: 48px;
  border-radius: 16px;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
  position: absolute;
  left: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.fullscreen-gallery__fullscreen-btn:hover,
.fullscreen-gallery__leave-fullscreen-btn:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.fullscreen-gallery__fullscreen-btn {
  top: 100%;
  margin-top: 46px;
}
.fullscreen-gallery__fullscreen-btn::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: -30px;
  left: -30px;
  right: -30px;
  bottom: -30px;
}
@media only screen and (max-width: 768px) {
  .fullscreen-gallery__fullscreen-btn {
    top: auto;
    bottom: 116px;
    margin: 0;
  }
}
.fullscreen-gallery__leave-fullscreen-btn {
  bottom: 24px;
}
.fullscreen-gallery__ico-expand,
.fullscreen-gallery__ico-cross {
  width: 24px;
  height: 24px;
}
.fullscreen-gallery__close-fullscreen-btn {
  transition: color 0.1s linear 0s;
  will-change: color;
  color: rgba(0, 0, 0, 0.1);
  display: block !important;
  position: absolute;
  top: 20px;
  right: 20px;
}
.fullscreen-gallery__close-fullscreen-btn:hover {
  color: rgba(0, 0, 0, 0.2);
}
.fullscreen-gallery__arrow {
  transition: transform 0.1s linear 0s;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 10%;
}
.fullscreen-gallery__arrow:hover {
  transform: scale(0.85);
}
@media only screen and (max-width: 1024px) {
  .fullscreen-gallery__arrow {
    display: none;
  }
}
.fullscreen-gallery__arrow--left {
  top: 0;
  left: 0;
}
.fullscreen-gallery__arrow--right {
  top: 75px;
  right: 0;
}
@media only screen and (max-width: 768px) {
  .fullscreen-gallery .fotorama__wrap {
    padding-bottom: 196px;
  }
}
.fullscreen-gallery .fotorama__fullscreen-icon,
.fullscreen-gallery .fotorama__arr {
  display: none !important;
}
.fullscreen-gallery .fotorama__nav-wrap {
  border-radius: 0 0 24px 24px;
  backdrop-filter: blur(4px);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
@media only screen and (max-width: 768px) {
  .fullscreen-gallery .fotorama__nav-wrap {
    border-radius: 0px;
  }
}
.fullscreen-gallery .fotorama__nav {
  text-align: left;
  padding-left: 16px;
}
.fullscreen-gallery .fotorama__thumb-border,
.fullscreen-gallery .fotorama__thumb {
  border-radius: 8px;
}
.fullscreen-gallery .fotorama__thumb-border {
  border-color: #d24676;
}
.fullscreen .fotorama__fullscreen-icon,
.fullscreen .fotorama__arr {
  display: none !important;
}
.fullscreen .fullscreen-gallery__arrow {
  display: flex !important;
}
@media only screen and (max-width: 768px) {
  .fullscreen .fullscreen-gallery__arrow {
    display: none !important;
  }
}
.fullscreen .fullscreen-gallery__arrow .slider-arrow {
  transition: color 0.1s linear 0s;
  will-change: color;
  color: rgba(0, 0, 0, 0.1);
}
.fullscreen .fullscreen-gallery__arrow:hover .slider-arrow {
  color: rgba(0, 0, 0, 0.3);
}
.add-review {
  display: none;
  border-radius: 24px;
  background: #fff;
  text-align: center;
  width: 100%;
  max-width: 320px;
  padding: 32px 24px;
  margin: 0 auto;
}
.add-review.shown {
  display: block;
}
.add-review__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 24px;
}
.add-review__socials {
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
}
.add-review__social-btn {
  transition: color 0.1s linear 0s;
  will-change: color;
  color: #57aab9;
  position: relative;
}
.add-review__social-btn:hover {
  color: #d24676;
}
.add-review__social-btn::after {
  font-size: 0;
  position: absolute;
  bottom: 3px;
  right: 0;
}
.add-review__social-btn.active::after {
  content: url(img/ico_current-social.svg);
}
.add-review__social-btn + .add-review__social-btn {
  margin-left: 8px;
}
.add-review__social-icon {
  width: 48px;
  height: 48px;
}
.add-review__need-auth {
  font-family: 'CenturyGothic', sans-serif;
  color: #57aab9;
  font-size: 13px;
  opacity: 0;
}
.disabled .add-review__need-auth {
  opacity: 1;
}
.add-review__content {
  position: relative;
}
.add-review__content::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
}
.disabled .add-review__content::before {
  content: '';
}
.add-review__marks {
  justify-content: center;
  margin-bottom: 24px;
}
.add-review__message-container {
  margin-bottom: 1px;
}
.add-review__textarea {
  min-height: 48px;
  max-height: 170px;
}
.add-review__count {
  font-family: 'CenturyGothic', sans-serif;
  font-size: 13px;
  color: rgba(87, 170, 185, 0.5);
  line-height: 29px;
  text-align: right;
}
.add-review__submit {
  min-width: 216px;
}
.delivery-info {
  border-radius: 24px;
  display: none;
  background: #fff;
  text-align: center;
  padding: 32px 24px;
  max-width: 320px;
}
.delivery-info.shown {
  display: block;
}
.delivery-info__subtitle {
  margin-bottom: 8px;
}
.delivery-info__time {
  color: #57aab9;
}
.delivery-info__text {
  font-family: 'CenturyGothic', sans-serif;
  color: #676767;
  line-height: 23px;
  margin-top: 24px;
}
.delivery-info__text p + p {
  margin-top: 22px;
}
.delivery-info__btn {
  width: 216px;
  margin-top: 32px;
}
